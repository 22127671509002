/* .experience-container{
  margin: 0 118px 0 118px;
} */


.experience-title{
  margin-top: 54px;
  height: 82px;
  width: 806px;
  color: #000000;
  font-family: "Playfair Display";
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 81.6px;
}

.experience-info{
  padding-top: 40px;
  height: 150px;
  width: 790px;
  color: #000000;
  font-family: 'Rubik';
  font-size: 18px;
  letter-spacing: 0.38px;
  line-height: 26px;
  padding-bottom: 40px
}

.resume{
  color: #FF6007;
  font-family: 'Rubik';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 28px;
}

@media screen and (max-width:800px) {
  .experience-info{
  margin: 0px;
  width: 620px;
  padding-top: 40px;
  color: #000000;
  font-family: 'Rubik';
  font-size: 18px;
  letter-spacing: 0.38px;
  line-height: 26px;
  padding-bottom: 60px
  }
}
