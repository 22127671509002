
.projects-title{
  height: 82px;
  width: 806px;
  color: #000000;
  font-family: "Playfair Display";
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 81.6px;
}

.project{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
}

.projects-container{
  margin-top: 0px 54px 0px 54px;
}


.project-image{
  height: 243px;
  width: 420px;
  border-radius: 5px;
}

.project-image-mobile{
  display: none
}

.project-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: left;
  padding: 40px;

}

.project-title{
  height: 34px;
  width: 242px;
  color: #000000;
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.75px;
  line-height: 33.6px;
  padding-bottom: 15px;
}

.project-desc{
  height: 81px;
  width: 430px;
  color: #000000;
  font-family: 'Rubik';
  font-size: 18px;
  letter-spacing: 0.38px;
  line-height: 26px;
  padding-bottom: 30px
}

.link{
  color: #FF6007;
  font-family: 'Rubik';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 28px;
}

@media screen and (max-width:800px) {
  .project{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 500px;
  }

  .project-image,
  .queue-image{
   display: none;
   padding: 0px;
  }

  .project-image-mobile{
    display: inline-block;
    height: 130px;
    width: 200px;
    align-items: center;
    padding-left: 150px
  }

}

