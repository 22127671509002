.footer{
  background-color: black;
  display: flex;
  height: 345px;
  border-radius: 3px;
  background-color: #000000;
  color: white;
  justify-content: space-around;
  align-items: center;
}

.logo-footer{
  height: 45.27px;
  width: 83px;
}

.social-icon{
  height: 41px;
  width: 41px;
  margin: 11.8px;
}

.footer-email{
  height: 28px;
  width: 217px;
  color: #FF6007;
  font-family: 'Rubik';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 28px;
}

.bottom{
  color: white;
}

@media screen and (max-width:800px) {
  .footer{
    background-color: black;
    width: 800px;
    display: flex;
    border-radius: 3px;
    background-color: #000000;
    color: white;
    justify-content: space-around;
    align-items: center;
  }
}
