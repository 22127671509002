.aboutMe-conatiner{
  margin-top: 54px;
  height: 700px;
  width: 550px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.title{
  height: 82px;
  width: 806px;
  color: #000000;
  font-family: "Playfair Display";
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 81.6px;
}

.photo{
  height: 197px;
  width: 197px;
}

.aboutMe-info{
  padding-top: 40px;
  height: 81px;
  width: 550px;
  color: #000000;
  font-family: 'Rubik';
  font-size: 18px;
  letter-spacing: 0.38px;
  line-height: 26px;
  padding-bottom: 20px;
}

.spotify-iframe{
  align-content: center;
  margin-left: 45px;
  padding: 80px;
}

@media screen and (max-width:800px) {
  .aboutMe-conatiner{
    flex-direction: column;
    justify-content: space-around;
    padding-top: 300px;
    width: 620px;
  }

  .photo{
    align-content: center;
  }

  .spotify-iframe{
    align-content: center;
    margin: 300px 0px;
  }
}
