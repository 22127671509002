.intro-container{
  margin: auto;
  width: 70%;
  padding: 60px 0 60px 0;
  font: 'Rubik';
}

.name-container{
  height: 200px;
  width: 396px;
}

.name{
  font-family: 'Playfair Display Black';
  font-size: 100px;
  font-weight: 900;
  line-height: 93.86px;
  letter-spacing: 0;
  position: relative;
  padding-left: 5px;
  top: 28px;
}

.rectangle {
  height: 34px;
  width: 396px;
  border-radius: 10px;
  background: linear-gradient(153.09deg, #FFCE00 0%, #FF6007 100%, #FF5C07 100%);
}

.intro-text{
  padding-top: 50px;
  height: 177px;
  width: 690px;
  color: #000000;
  font-family: Rubik;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 37px;
}

@media screen and (max-width:800px) {
  .intro-container{
    margin: 0px;
    font: 'Rubik';
  }

  .intro-text{
    /* padding: 50px 0px 70px; */
    /* height: 177px;
    width: 620px; */
    color: #000000;
    font-family: Rubik;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 37px;
  }
}


