.navbar{
  display: flex;
  justify-content: space-between;
  /* margin: 30px 20px; */
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.left{
  display: flex;
  justify-content: space-around;
}

.nav-logo{
  height: 46px;
  width: 83px;
  padding-right: 30px;
}

.right{
  display: flex;
  justify-content: space-evenly;
}

.right-item{
  margin: 0 30px 0 30px;
}

a.nav-link{
  padding: 10px;
  align-content: center;
  color: black;
  text-decoration: none;
}

a.nav-link:hover{
  color: #FF6007;
  text-decoration: none;
}

@media screen and (max-width:800px) {
  .navbar{
    display: flex;
    justify-content: space-evenly;
    /* margin: 5px; */
    align-items: center;
    /* font-weight: bold; */
    font-size: 12px;
  }

  .right-item{
    margin: 0px;
  }
}

@media screen and (max-width:400px) {
  .navbar{
    display: flex;
    justify-content: center;
    width: 500px;
    margin: 0px;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
  }
  .right-item{
    margin: 0px;
  }
}

